export const bgWhiteIcons = [
  { icon: ['fab', 'tiktok'], color: 'tiktok', bg: 'white', href: 'https://www.tiktok.com/@request.system' },
  { icon: ['fab', 'instagram'], color: 'instagram', bg: 'white', href: 'https://www.instagram.com/requestsystem' },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', bg: 'white', href: 'https://www.linkedin.com/company/request-system' }
];

export default [
  { icon: ['fab', 'tiktok'], color: 'tiktok', href: 'https://www.tiktok.com/@request.system' },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', href: 'https://www.linkedin.com/company/request-system' },
  { icon: ['fab', 'instagram'], color: 'instagram', href: 'https://www.instagram.com/requestsystem' },
];