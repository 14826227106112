export const homeRoles = ['RS', 'CM-A', 'CM-V', 'G', 'LE-A', 'LE-V', 'F'];
export const lojaRoles = ['RS'];
export const funcaoRoles = ['RS'];
export const pessoaRoles = ['RS', 'CM-A'];
export const contatoRecebidoRoles = ['RS'];
export const usuarioRoles = ['RS', 'CM-A'];
export const dispositivoRoles = ['RS'];
export const eventoRoles = ['RS', 'G', 'F'];
export const produtoRoles = ['RS', 'CM-A', 'CM-C'];
export const formaPagamentoRoles = ['RS', 'CM-A', 'CM-C'];
export const cardapioRoles = ['RS', 'CM-A', 'CM-C'];
export const categoriaRoles = ['RS', 'CM-A', 'CM-C'];
export const mainCardapioRoles = ['RS', 'CM-A', 'CM-C'];
export const notificacaoRoles = ['RS', 'G', 'CM-A'];
export const defaultDashboardRoles = ['RS', 'G', 'C', 'CM-A', 'CM-V', 'F'];
export const mainDashboardRoles = ['RS', 'G', 'CM-A', 'CM-V', 'LE-A', 'LE-V'];
export const lojaDashboradRole = ['RS', 'G', 'C', 'CM-A', 'CM-V', 'LE-A', 'LE-V', 'F'];
export const editRoles = ['RS', 'CM-A', 'CM-C'];